import axios from 'axios';
import {
    useEffect, 
    useState,
} from 'react';
import Skeleton from '@mui/material/Skeleton';
import {
    LOADING,
    ERROR,
    SUCCESS,
} from 'constants/index';

import './LyricsPanel.scss';

interface ILyricsPanelProps {
    className?: string;
    lyricsUrl: string;
    title?: string;
}

function LyricsPanel(props: ILyricsPanelProps) {
    const {
        className = '', 
        lyricsUrl = '',
        title = '',
    } = props;

    const [lyricsContent, setLyricsContent] = useState('');
    const [lyricStatus, setLyricStatus] = useState(LOADING);

    useEffect(() => {
        const asynFn = async () => {
            try {
                setLyricStatus(LOADING);
                const lyricsText = await axios.get(lyricsUrl, { responseType: 'text' });

                setLyricsContent(lyricsText.data);
                setLyricStatus(SUCCESS);
            } catch (err) {
                setLyricStatus(ERROR);
            }
        };

        if (lyricsUrl) {
            asynFn();
        }
    }, [lyricsUrl]);

    const classNames = `lyric-Panel ${className}`.trim();

    let lyricsBlock = (
        <>
            <Skeleton 
                variant="text"
                sx={{ fontSize: '1rem' }}
            />
            <Skeleton 
                variant="text"
                sx={{ fontSize: '1rem' }} 
                width={120}
            />
            <Skeleton 
                variant="text"
                sx={{ fontSize: '1rem' }} 
            />
        </>
    );

    if (lyricStatus === SUCCESS) {
        lyricsBlock = (
            <pre className="lyric-Panel__pre-line">
                {`${lyricsContent}`}
            </pre>
        );
    }

    return (
        <div className={classNames}>
            {title && (
                <h3 className="lyric-Panel__title">{title}</h3>
            )}
            {lyricsBlock}
        </div>
    );
}

export default LyricsPanel;

