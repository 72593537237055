import Button, { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import * as tracker from 'utils/tracker';
import { MOBILE_SHARE } from 'constants/trackingEvents';
import {
    asyncShare , 
    findMusicDetails 
} from 'utils';
import { SHARE_CONFIG } from 'constants/index';

import './ShareButton.scss';
import { ICurrentMusic } from 'interfaces';

export interface IShareButton extends ButtonProps {
    currentMusic: ICurrentMusic;
    [key: string]: any
}

function ShareButton(props: IShareButton) {
    const {
        className = 'share-button',
        currentMusic = {} as ICurrentMusic,
        variant = 'outlined',
        size = 'large',
        endIcon,
        onClick = ()=>{},
        asTextButton = false,
        stopPropagation = true,
        ...restProps
    } = props;

    const mergedProps = {
        ...restProps,
        className,
        variant,
        size,
    };

    let shareUrl = SHARE_CONFIG.url;

    const Icon = endIcon || <ShareOutlinedIcon />;

    const handleClick = async (e: any)=> {
        if (stopPropagation) {
            e.stopPropagation();
        }
        const {
            musicId ='',
            playlistId = ''
        } = currentMusic;
        
        if (playlistId) {
            shareUrl = `${SHARE_CONFIG.url}/${playlistId}`;
    
            if (musicId) {
                shareUrl = `${SHARE_CONFIG.url}/${playlistId}/${musicId}`;
            }
        }
    
        const shareOptions = {
            ...SHARE_CONFIG,
            url: shareUrl,
        };

        const musicDetails = findMusicDetails(currentMusic);
    
        if (musicDetails && musicDetails.musicName) {
            shareOptions.text = `Listen "${musicDetails.musicName}"`;
        
            await asyncShare(shareOptions);

            tracker.event(MOBILE_SHARE, { shareUrl });
        }

        e.shareData = shareOptions;
        onClick(e);
    };

    if (!asTextButton) {
        return (
            <IconButton
                className='share-button__icon-button' 
                color="inherit"
                onClick={handleClick}
            >
                <ShareOutlinedIcon />
            </IconButton>
        );
    }

    return (
        <Button 
            {...mergedProps}
            endIcon={Icon}
            onClick={handleClick}
        >
            Share
        </Button>
    );
}

export default ShareButton;
