import {  MouseEvent } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
    DEFAULT_PLAYLIST_TITLE, 
    MY_SONGS_TITLE
} from 'constants/index';

import './PlaylistToggle.scss';

interface IPlaylistToggleProps {
    className?: string;
    selectedValue?: string;
    // eslint-disable-next-line no-unused-vars
    onChange?: (arg: string) => any;
    isAuthenticated?: boolean;
}


function PlaylistToggle(props: IPlaylistToggleProps) {
    const {
        className = '',
        selectedValue = '',
        onChange = ()=>{},
        isAuthenticated = false,
    } = props;

    const handleChange = (
        event: MouseEvent<HTMLElement>,
        newValue: string,
    ) => {
        onChange(newValue);
    };

    if (!isAuthenticated) {
        return (
            <h3 className='playlist-toggle__title'>
                {DEFAULT_PLAYLIST_TITLE}
            </h3>
        );
    }

    const classNames = `${className} playlist-toggle`.trim();

    return (
        <ToggleButtonGroup
            className={classNames}
            // color="primary"
            size="small"
            value={selectedValue}
            exclusive
            onChange={handleChange}
            aria-label="Playlist"
        >
            <ToggleButton value={DEFAULT_PLAYLIST_TITLE}>
                {DEFAULT_PLAYLIST_TITLE}
            </ToggleButton>
            <ToggleButton value={MY_SONGS_TITLE}>
                {MY_SONGS_TITLE}
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

export default PlaylistToggle;
