/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-max-props-per-line */
import {
    useEffect,
    useState 
} from 'react';
import { 
    Link,
    useNavigate,
    useLocation  
} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import WiseTreeLogo from "components/WiseTreeLogo";
import useSticky from 'hooks/useSticky';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import {
    PLAYLIST_BY_ID,
    CREATE,
    SIGN_IN,
    MY_SONGX,
    PROFILE,
    REWARDS,
} from 'constants/routes';


import './MainHeader.scss';
import { IPlanObject } from 'interfaces';

interface IMainHeaderProps {
    className?: string;
    isDrawerOpen: boolean;
    // eslint-disable-next-line no-unused-vars
    toggleDrawer: (arg: boolean) => any;
}

const showBackButtonRoutes = [PLAYLIST_BY_ID, CREATE, SIGN_IN, MY_SONGX, PROFILE, REWARDS];

function MainHeader(props: IMainHeaderProps) {
    const {
        className = '',
        isDrawerOpen,
        toggleDrawer = ()=>{},
    } = props;

    const [stickyRef, isSticky] = useSticky(5);
    const [locationState, setlocationState] = useState<IPlanObject>();
    const navigate = useNavigate();
    const location = useLocation();

    const {
        pathname,
    } = useLocation();

    useEffect(() => {
        if (location && location.state) {
            setlocationState(location.state);
        }
    }, [location]);
    

    const stickyCss = isSticky
        ? 'main-header--sticky'
        : '';

    let showBackButton = false;

    if (pathname !== '/') {
        const showBackButtonRoute = showBackButtonRoutes.find((route)=> pathname.indexOf(route) >= 0);
        showBackButton = !!showBackButtonRoute;
    }

    const handleDrawer = ()=> {
        toggleDrawer(!isDrawerOpen);
    };

    const handleBack = ()=> {
        if (locationState) {
            if (locationState.backToHome) {
                navigate('/');
                return;
            }
        }
        navigate(-1);
    };

    const classNames = `main-header ${className}`.trim();

    return (
        <div className={stickyCss}>
            <header className={classNames} ref={stickyRef}>
                {!showBackButton && (
                    <IconButton 
                        className="main-header__btn-drawer"
                        onClick={handleDrawer}
                    >
                        <MenuIcon />
                    </IconButton>
                )
                }
                { showBackButton && (
                    <IconButton 
                        className="main-header__btn-back"
                        onClick={handleBack}
                    >
                        <ArrowBackIosNewIcon />
                    </IconButton>
                )}
                <Link to="/">
                    <WiseTreeLogo />
                </Link>
                <div />
            </header>
        </div>
    );
}

export default MainHeader;
