import { connect } from 'react-redux';
import {
    getPlaylist,
    changeMusic,
    setPlayerOpen,
    setUserPlaylist,
} from 'actions/music';
import {
    getArtists,
    getMusicSelections,
} from 'actions/creation';
import Home from 'pages/Home';

import {
    IPlanObject, 
    IRootState,
    IChangeMusic
} from 'interfaces/index';

const mapStateToProps = (state: IRootState) => ({ 
    music: state.music,
    isAuthenticated: !!state.account?.authenticated,
    user: state.account.user || {},
});

const mapDispatchToProps = (dispatch: any) => ({ 
    getPlaylist: (opt: IPlanObject) => dispatch(getPlaylist(opt)),
    getArtists: () => dispatch(getArtists()), 
    getMusicSelections: () => dispatch(getMusicSelections()), 
    changeMusic: (data: IChangeMusic) => dispatch(changeMusic(data)),
    setPlayerOpen: (isOpen: boolean) => dispatch(setPlayerOpen(isOpen)),
    setUserPlaylist: (opt: IPlanObject) => dispatch(setUserPlaylist(opt))
});

const connector = connect(mapStateToProps, mapDispatchToProps);
// @ts-ignore
export default connector(Home);
