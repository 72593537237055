import { connect } from 'react-redux';
import { updateLike } from 'actions/music';
import SongList from 'components/SongLlist';

import { IRootState } from 'interfaces/index';

const mapStateToProps = (state: IRootState) => ({ 
    currentMusic: state.music.currentMusic,
    createMusicQueue: state.music.createMusicQueue,
    createMusicStatus: state.music.createMusicStatus,
    isAuthenticated: !!state.account?.authenticated,
    user: state.account.user || {},
});

const mapDispatchToProps = (dispatch: any) => ({ updateLike: (data: any) => dispatch(updateLike(data)), });

const connector = connect(mapStateToProps, mapDispatchToProps);
// @ts-ignore
export default connector(SongList);
