import MusicPlayerControls from 'containers/MusicPlayerControls';
import ProgressInputRange from 'components/MusicPlayer/ProgressInputRange';
import LyricsPanel from 'components/MusicPlayer/LyricsPanel';
import PromptPanel from 'components/PromptPanel';

import './MusicDetails.scss';

import { IPlanObject } from 'interfaces';

interface IMusicDetailsProps {
    className?: string;
    currentTrack: IPlanObject;
    autoPlayAllPlaylists: boolean;
    [key: string]: any;
}

function MusicDetails(props: IMusicDetailsProps) {
    const {
        className = '',
        currentTrack = {},
        duration = 0,
        audioRef,
        progressInputRangeRef,
        progressTimeRef,
        handleNext = ()=> {},
        handlePrevious = ()=> {},
        handleRepeat = ()=>{},
        handlesRepeatSingle = ()=>{},
        handleShuffle = ()=>{},
        autoPlayAllPlaylists = false,
    } = props;

    const {
        cover = '',
        musicName = '',
        author = '',
        lyrics = '',
        context = {},
    } = currentTrack;

    const classsNames = `music-details ${className}`.trim();

    return (
        <div className={classsNames}>
            <div 
                className="music-details__music-img" 
                style={{ backgroundImage: `url(${cover})` }}
            />
            <h3 className="bold">{musicName}</h3>
            <small>{author}</small>

            <ProgressInputRange {...{
                progressInputRangeRef,
                progressTimeRef,
                audioRef,
                duration
            }} />
            <MusicPlayerControls 
                {...{
                    audioRef,
                    handleNext,
                    handlePrevious,
                    showVolumn: false,
                    autoPlayAllPlaylists,
                    handleRepeat,
                    handlesRepeatSingle,
                    handleShuffle,
                }}
            />
            <div className="music-details__container">
                <PromptPanel 
                    className="music-details__prompt"
                    musicContext={context} 
                />
                <LyricsPanel 
                    className="music-player__lyrics-panel"
                    lyricsUrl={lyrics}
                    title="Lyrics"
                />
            </div>
        </div>
    );
}

export default MusicDetails;
