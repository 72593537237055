import { useNavigate } from 'react-router-dom';
import HorizontalList from "components/HorizontalList";
import SongListItem from "components/SongListItem";
import {
    LOADING,
    ERROR,
} from 'constants/index';
import {
    ICurrentMusic,
    IPlaylistData,
} from "interfaces";

interface ITopSongsProps {
    className?: string;
    currentMusic: ICurrentMusic | never;
    title?: string;
    topSongs: IPlaylistData | never;
    disabled?: boolean;
    // eslint-disable-next-line no-unused-vars
    updateLike: (arg: any) => any;
    // eslint-disable-next-line no-unused-vars
    updatePlayerStatus: (arg: boolean) => any;
    [key: string]: any;
}

function TopSongs(props: ITopSongsProps) {
    const {
        className = '',
        currentMusic = {} as ICurrentMusic,
        title = 'Top songx',
        disabled = false,
        topSongs,
        updateLike = () => {},
        updatePlayerStatus = () => {},
    } = props;

    const navigate = useNavigate();

    // eslint-disable-next-line no-unused-vars
    const handleChangeMusic = (e: any, data: any) => {
        e.preventDefault();

        if (disabled) {
            return;
        }

        const {
            playlistId,
            musicId,
        } = data;

        if (playlistId && musicId) {
            if (!currentMusic.isPlaying) {
                updatePlayerStatus(true);
            }
            navigate(`/${playlistId}/${musicId}`);
        }
    };

    const getActiveCss = (index = 0) => {
        if (currentMusic.playlistId === topSongs.id && (index === currentMusic.index)) {
            return 'playlist__list--active';
        }
        return '';
    };

    const classNames = `top-songs-list ${className}`.trim();

    if (!topSongs || topSongs.status === LOADING) {
        return null;
    }

    if (!Array.isArray(topSongs.musics) || topSongs.status === ERROR) {
        return (
            <div className={classNames}>
                <h3>
                    {title}
                </h3>
                <p>
                    Not available at the moment.
                </p>
            </div>
        );
    }

    return (
        <div className={classNames}>
            <h3>
                {title}
            </h3>
            <HorizontalList >
                {
                    topSongs.musics.map((track, index) => (
                        <li 
                            key={track.musicId}
                            className={`${getActiveCss(index)}`.trim()}
                        >
                            <SongListItem 
                                idx={index}
                                playlistId={topSongs.id}
                                {...track}
                                isSelected={!disabled && (index === currentMusic.index && topSongs.id === currentMusic.playlistId)}
                                updateLike={updateLike}
                                onClick={handleChangeMusic}
                                disabled={disabled}
                            />
                        </li>))
                }
            </HorizontalList>
        </div>
    );
}

export default TopSongs;
