import {
    LOGIN_PENDING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOFF_SUCCESS,
    LOGOFF_ERROR,
    UPDATING_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
} from 'constants/actionTypes';
import {
    ERROR,
    SUCCESS,
    LOADING,
    UPDATING,
} from 'constants/index';
import { IAccountState } from 'interfaces';

interface IAction {
    type: string,
    payload: any
}

export default (state = {} as IAccountState, action = {} as IAction) => {
    const payload = action.payload || {};

    switch (action.type) {
        case LOGIN_PENDING: {
            return {
                ...state,
                accountStatus: LOADING,
            };
        }
        case LOGIN_SUCCESS: {
            return {
                ...state,
                ...payload,
                authenticated: !!payload.user,
                accountStatus: SUCCESS,
            };
        }
        case LOGIN_ERROR:
        case LOGOFF_ERROR:
        case UPDATE_PROFILE_ERROR: {
            return {
                authenticated: false,
                accountStatus: ERROR,
            };
        }
        case LOGOFF_SUCCESS: {
            return { authenticated: false };
        }
        case UPDATING_PROFILE: {
            return {
                ...state,
                accountStatus: UPDATING,
            };
        }
        case UPDATE_PROFILE_SUCCESS: {
            if (state?.user?.id !== payload.id) {
                return state;
            }
            return {
                ...state,
                user: {
                    ...state.user,
                    ...payload
                },
                accountStatus: SUCCESS,
            };
        }
        default:
            return state;
    }
};
