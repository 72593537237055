import axios from 'axios';
import {
    LOADING_MUSIC,
    LOAD_MUSIC_SUCCESS,
    LOAD_MUSIC_ERROR,
    LOADING_PLAYLIST,
    LOAD_PLAYLIST_SUCCESS,
    LOAD_PLAYLIST_ERROR,
    UPDATE_CURRENT_MUSIC_INDEX,
    UPDATE_LIKE,
    UPDATE_VIEW,
    UPDATE_PLAYER_STATUS,
    UPDATE_PLAYER_OPEN_STATE,
    UPDATE_REPEAT_PLAYLIST,
    UPDATE_REPEAT_SINGLE,
    UPDATE_SHUFFLE_PLAYLIST,
} from 'constants/actionTypes';
import { 
    convertPlaylistData,
    convertMusicData,
} from 'dataConverter';
import { 
    PLAYLIST_API,
    MUSIC_API,
    MUSIC_UPDATE_VIEW_API,
    MUSIC_UPDATE_LIKE_API,
    DEFAULT_PLAYLIST_ID,
    USER_SONGS_PLAYLIST_ID,
} from 'constants/api';

import {
    IPlanObject, 
    IChangeMusic,
    ITrack,
    IUserState,
    IPlaylistData
} from 'interfaces';

export const getPlaylist: any = (opt = {} as IPlanObject) => (dispatch: any) => {
    const {
        playlistId = '',
    } = opt;

    if (!playlistId) {
        return Promise.reject(new Error('missing playlistId'));
    }

    dispatch({ 
        type: LOADING_PLAYLIST, 
        payload: { playlistId } 
    });

    return axios({
        method: 'get',
        url: `${PLAYLIST_API}/${playlistId}`,
    }).then((response) => {
        const {
            data,
        } = response;

        const playlistData = data;

        if (playlistData && playlistData.musics) {
            playlistData.musics = convertPlaylistData(playlistData.musics);
        }

        if (playlistData.id === DEFAULT_PLAYLIST_ID) {
            playlistData.title = 'Latest';
        }
        
        dispatch({
            type: LOAD_PLAYLIST_SUCCESS,
            payload: {
                playlistId,
                playlistData,
            }
        });

        return playlistData;
    }).catch((error: any) => {
        dispatch({ 
            type: LOAD_PLAYLIST_ERROR,
            payload: { playlistId }
        });

        return error;
    });
};

export const getMusic = (opt = {} as IPlanObject) => (dispatch: any) => {
    dispatch({ type: LOADING_MUSIC });

    const {
        musicId = '',
    } = opt;

    if (!musicId) {
        dispatch({
            type: LOAD_MUSIC_SUCCESS,
            payload: {},
        });

        return Promise.resolve({});
    }

    return axios({
        method: 'get',
        url: `${MUSIC_API}/${musicId}`,
    }).then((response) => {
        const {
            data,
        } = response;

        const musicData = convertMusicData(data);

        dispatch({
            type: LOAD_MUSIC_SUCCESS,
            payload: musicData,
        });

        return musicData;
    }).catch((error: any) => {
        dispatch({ type: LOAD_MUSIC_ERROR });

        return error;
    });
};

export const changeMusic = (data = {} as IChangeMusic) => (dispatch: any) => {
    dispatch({ 
        type: UPDATE_CURRENT_MUSIC_INDEX,
        payload: data
    });
};

export const updatePlayerStatus = (isPlaying = false) => (dispatch: any) => {
    dispatch({ 
        type: UPDATE_PLAYER_STATUS,
        payload: isPlaying
    });
};

export const updateLike = (data = {} as IPlanObject) => (dispatch: any) => {
    const {
        musicId = '',
    } = data;

    // TODO - debounce this call as user allow to keep pressing like button
    // make 1 call with accumulated likes number
    dispatch({ 
        type: UPDATE_LIKE,
        payload: data
    });

    // API to patch like only
    return axios({
        method: 'post',
        url: `${MUSIC_UPDATE_LIKE_API}/${musicId}`,
    }).then((response)=> {
        const {
            // eslint-disable-next-line no-shadow, no-unused-vars
            data,
        } = response;
        // console.log('updateLike res: ', data);
        // eslint-disable-next-line no-unused-vars
    }).catch((error: any)=>{
        // console.log('updateLike error: ', error);
    });
};

// updateView means user listen to the music on the player, everytime.
export const updateView = (data = {} as IPlanObject) => (dispatch: any) => {
    const {
        musicId = '',
    } = data;

    dispatch({ 
        type: UPDATE_VIEW,
        payload: data
    });

    // API to patch like only
    return axios({
        method: 'post',
        url: `${MUSIC_UPDATE_VIEW_API}/${musicId}`,
    }).then((response)=> {
        const {
            // eslint-disable-next-line no-shadow, no-unused-vars
            data,
        } = response;
        // console.log('updateLike res: ', data);
        // eslint-disable-next-line no-unused-vars
    }).catch((error: any)=>{
        // console.log('updateLike error: ', error);
    });
};

export const setPlayerOpen = (isOpen = false) => (dispatch: any) => {
    dispatch({ 
        type: UPDATE_PLAYER_OPEN_STATE,
        payload: isOpen
    });
};

export const setUserPlaylist = (opt = {} as IPlanObject) => (dispatch: any) => {
    const {
        defaultPlaylistData = {} as IPlaylistData, 
        user = {} as IUserState
    } = opt;

    const userMusics = defaultPlaylistData?.musics?.filter((music: ITrack) => music.email === user?.email);

    const userPlaylist = {
        id: USER_SONGS_PLAYLIST_ID,
        musics: userMusics,
        title: 'My songs'
    };

    dispatch({
        type: LOAD_PLAYLIST_SUCCESS,
        payload: {
            playlistId: USER_SONGS_PLAYLIST_ID,
            playlistData: userPlaylist,
        }
    });
};

export const setRepeatPlaylist = (isRepeat: boolean) => (dispatch: any) => {
    dispatch({
        type: UPDATE_REPEAT_PLAYLIST,
        payload: isRepeat
    });
};

export const setRepeatSingle = (isRepeat: boolean) => (dispatch: any) => {
    dispatch({
        type: UPDATE_REPEAT_SINGLE,
        payload: isRepeat
    });
};

export const setShufflePlaylist = (isShuffle: boolean) => (dispatch: any) => {
    dispatch({
        type: UPDATE_SHUFFLE_PLAYLIST,
        payload: isShuffle
    });
};
