import React, { useRef } from 'react';
import { useDraggable } from "react-use-draggable-scroll";
import { IPlanObject } from 'interfaces';

import './HorizontalList.scss';

interface IHorizontalListProps {
    className?: string;
    swipeOptions?: IPlanObject;
    showControls?: boolean;
    children: React.ReactNode;
}

/**
 * Carousel
 * @description simple carousel makes children element swipeable
 * ref: https://www.npmjs.com/package/@types/react-swipe
 * @param {object} props 
 * @returns {object} react component
 */
function HorizontalList(props: IHorizontalListProps) {
    const {
        className = '',
        children,
        ...restProps
    } = props;

    const reactSwipeRef = useRef<HTMLDivElement>(null) as any;

    const {
        events 
    } = useDraggable(reactSwipeRef);

    const classNames = `horizontalList ${className}`.trim();
   
    return (
        <div className={classNames}>
            <ul
                className="horizontalList__list"
                ref={reactSwipeRef} 
                {...events}
                {...restProps}
            >
                {children}
            </ul>
        </div>
    );
};

export default HorizontalList;
