const themeOptions = {
    palette: {
        type: 'light',
        primary: { main: '#ADD038' },
        secondary: { main: '#f53300' },
        background: {
            default: '#000000',
            paper: '#292929',
        },
        text: {
            primary: '#fff',
            secondary: '#e6e6e6',
            disabled: '#999',
            hint: '#999',
        },
        iconBase: { main: '#FFF' },
        divider: 'rgba(255,255,255,0.3)',
    },
};

export default themeOptions;
