/* eslint-disable dot-notation */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { 
    signInWithProvider,
    signOutAsync
} from 'utils/firebase';
import {
    convertUserData,
    convertUserProfileUpdate,
    convertUpdatedProfileData
} from 'dataConverter';
import {
    LOGIN_PENDING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOFF_SUCCESS,
    LOGOFF_ERROR,
    UPDATING_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
} from 'constants/actionTypes';
import {
    USER_PROFILE_API,
    USER_PROFILE_UPDATE_API
} from 'constants/api';
import { IPlanObject } from 'interfaces';

const getSongxUserData = () => axios({
    method: 'get',
    url: USER_PROFILE_API
// eslint-disable-next-line no-unused-vars
}).catch((err: any) => {
    // console.log(err.message);
    throw err;
});

export const signInWithIdProvider = (provider: string) => async (dispatch: any) => {
    try {
        dispatch({ type: LOGIN_PENDING });

        const response = await signInWithProvider(provider);
        const songxUser = await getSongxUserData();

        const providerUserData = response?.user || {};
        const songxUserData = songxUser?.data || {};

        const userData = convertUserData(songxUserData, providerUserData);

        dispatch({ 
            type: LOGIN_SUCCESS,
            payload: userData
        });
    } catch(err) {
        // console.log('err: ', err);
        dispatch({ 
            type: LOGIN_SUCCESS,
            payload: LOGIN_ERROR
        });
    }
};

// If user already loggedin with Firebase, but not yet getting Songx profile.
// eg when user reload the page
export const getSongxUserProfile  = (providerData = {} as IPlanObject) => async (dispatch: any) => {
    try {
        dispatch({ type: LOGIN_PENDING });

        const songxUser = await getSongxUserData();
        const songxUserData = songxUser?.data || {};

        const userData = convertUserData(songxUserData, providerData);

        dispatch({ 
            type: LOGIN_SUCCESS,
            payload: userData
        });
    } catch(err) {
        dispatch({ 
            type: LOGIN_SUCCESS,
            payload: LOGIN_ERROR
        });
    }
};

export const signOutUser = () => (dispatch: any) => signOutAsync()
    .then(()=>{
        dispatch({ type: LOGOFF_SUCCESS });
    })
    .catch(() => {
        dispatch({ type: LOGOFF_ERROR });
    });

export const updateAccountProfile = (profileData = {} as IPlanObject) => (dispatch: any) => {
    dispatch({ type: UPDATING_PROFILE });

    const profileDataDto = convertUserProfileUpdate(profileData);

    return axios({
        method: 'post',
        url: USER_PROFILE_UPDATE_API,
        data: profileDataDto
    }).then((response) => {
        const {
            data,
        } = response;

        const updatedProfileData = convertUpdatedProfileData(data);

        dispatch({
            type: UPDATE_PROFILE_SUCCESS,
            payload: updatedProfileData,
        });

    }).catch((error: any) => {
        dispatch({ type: UPDATE_PROFILE_ERROR });

        throw error;
    });
};

