/* eslint no-unused-vars: 0 */ 
import {
    LOADING_ARTISTS,
    LOADING_ARTISTS_SUCCESS,
    LOADING_ARTISTS_ERROR,
    LOADING_MUSIC_SELECTION,
    LOADING_MUSIC_SELECTION_SUCCESS,
    LOADING_MUSIC_SELECTION_ERROR,
    CONVERTING_MIX_MUSIC_URL,
    CONVERT_MIX_MUSIC_URL_SUCCESS,
    CONVERT_MIX_MUSIC_URL_ERROR,
    CREATING_PLAYLIST,
    CREATE_PLAYLIST_SUCCESS,
    CREATE_PLAYLIST_ERROR,
    RESET_CREATE_PLAYLIST,
} from 'constants/actionTypes';
import {
    ERROR,
    LOADING,
    SUCCESS,
    CONVERTING,
    CONVERTED,
} from 'constants/index';
import { ICreationState, } from 'interfaces/index';

interface IAction {
    type: string,
    payload: any
}

export default (state = {} as ICreationState, action = {} as IAction) => {
    const {
        payload 
    } = action;

    switch (action.type) {
        case LOADING_ARTISTS: {
            return {
                ...state,
                artists: { status: LOADING }
            };
        }
        case LOADING_ARTISTS_SUCCESS: {
            return {
                ...state,
                artists: {
                    data: payload,
                    status: SUCCESS
                }
            };
        }
        case LOADING_ARTISTS_ERROR: {
            return {
                ...state,
                artists: { status: ERROR }
            };
        }
        case LOADING_MUSIC_SELECTION: {
            return {
                ...state,
                musicSelections: { status: LOADING }
            };
        }
        case LOADING_MUSIC_SELECTION_SUCCESS: {
            return {
                ...state,
                musicSelections: {
                    data: payload.data,
                    moods: payload.moods,
                    status: SUCCESS
                }
            };
        }
        case LOADING_MUSIC_SELECTION_ERROR: {
            return {
                ...state,
                musicSelections: { status: ERROR }
            };
        }
        case CONVERTING_MIX_MUSIC_URL: {
            return {
                ...state,
                currentMixMusic: { status: CONVERTING }
            };
        }
        case CONVERT_MIX_MUSIC_URL_SUCCESS: {
            const newMixMusicData = state?.musicSelections?.data.map((item)=> {
                const music = item;
                if (music.id === payload.id) {
                    music.filePath = payload.filePath;
                }
                return music;
            });

            return {
                ...state,
                musicSelections: {
                    ...state.musicSelections,
                    data: newMixMusicData,
                },
                currentMixMusic: { status: CONVERTED }
            };
        }
        case CONVERT_MIX_MUSIC_URL_ERROR: {
            return {
                ...state,
                currentMixMusic: { status: ERROR }
            };
        }
        case RESET_CREATE_PLAYLIST: {
            const newState = { ...state };
            delete newState.playlist;
            return newState;
        }
        case CREATING_PLAYLIST: {
            return {
                ...state,
                playlist: { status: LOADING } 
            };
        }
        case CREATE_PLAYLIST_SUCCESS: {
            return {
                ...state,
                playlist: { status: SUCCESS } 
            };
        }
        case CREATE_PLAYLIST_ERROR: {
            return {
                ...state,
                playlist: { status: ERROR } 
            };
        }
        default:
            return state;
    }
};
