import {
    useNavigate,
    Link 
} from 'react-router-dom';
import PendingList from 'components/SongLlist/PendingList';
import SongListItem from 'components/SongListItem';

import { ScrollPosition } from 'react-lazy-load-image-component';
import {
    MY_SONGS_TITLE,
    LOADING,
    ERROR,
} from 'constants/index';
import {
    CREATE,
    STEP_PREFIX
} from 'constants/routes';

import './SongList.scss';   

import { 
    ICurrentMusic,
    IPlaylistData,
    IPendingTrack, 
    IPlanObject,
    ITrack
} from 'interfaces/index';


interface IPlaylistProps {
    currentMusic?: ICurrentMusic;
    playlistData?: IPlaylistData;
    createMusicQueue?: IPendingTrack[];
    // eslint-disable-next-line no-unused-vars
    updateLike: (arg: any) => any;
    title?: string;
    disabled?: boolean;
    scrollPosition?: ScrollPosition;
    [key: string]: any
}

function SongList(props: IPlaylistProps) { 
    const {
        currentMusic = {} as ICurrentMusic,
        playlistData = {} as IPlaylistData,
        createMusicQueue = [],
        updateLike = () => {},
        disabled = false,
        scrollPosition,
    } = props;

    const {
        id = '',
        title = '',
        musics,
        status = ''
    } = playlistData;

    // const [currentMusics, setCurrentMusics] = useState<IPlanObject>({ musics: [] });
    const navigate = useNavigate();

    if (!playlistData || status === LOADING) {
        return null;
    }

    if (!Array.isArray(musics) || status === ERROR) {
        return (
            <div className="song-list">
                <h3>
                    {title}
                </h3>
                <p>
                    Not available at the moment.
                </p>
            </div>
        );
    }

    // eslint-disable-next-line no-unused-vars
    const handleChangeMusic = (e: any, data: IPlanObject) => {
        e.preventDefault();
    
        if (disabled) {
            return;
        }
    
        const {
            playlistId,
            musicId,
        } = data;
    
        if (playlistId && musicId) {
            navigate(`/${playlistId}/${musicId}`, { state: { playMusic: true } });
        }
    };

    const playlistId = id;

    const isEmptyUserPlaylist = !playlistData.musics?.length && playlistData.title === MY_SONGS_TITLE;

    return (
        <div className="song-list">
            
            <PendingList createMusicQueue={createMusicQueue} />
            <ul className="song-list__list song-list__list--grid">
                {
                    playlistData.musics?.map((track: ITrack, index: number) => {
                        const isSelectedSong = (track.musicId === currentMusic.musicId && playlistId === currentMusic.playlistId);

                        return (
                            <li 
                                key={track.musicId}
                                className={`${isSelectedSong ? 'song-list__list--active' : ''}`.trim()}
                            >
                                <SongListItem 
                                    idx={index}
                                    playlistId={playlistId}
                                    {...track}
                                    isSelected={!disabled && isSelectedSong}
                                    updateLike={updateLike}
                                    onClick={handleChangeMusic}
                                    disabled={disabled}
                                    scrollPosition={scrollPosition}
                                />
                            </li>
                        );
                    })
                }
            </ul>
            {
                isEmptyUserPlaylist && (
                    <>
                        <p>You haven&apos;t create any song yet.</p>
                        <p className='mb-4'>
                            <Link to={`${CREATE}/${STEP_PREFIX}1`}>Create</Link> your own songs now
                        </p>
                    </>
                )
            }
        </div>
    );
}

export default SongList;
