import React, { 
    useEffect,
    useState,
    Suspense,
} from 'react';
import { 
    useParams,
    useLocation,
} from 'react-router-dom';
import { Container } from '@mui/material';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import MiniPlayer from 'containers/MiniPlayer';
import SongList from 'containers/SongList';
import CreateButton from 'components/CreateButton';
import ShareButton from 'containers/ShareButton';
import {
    deviceInfo,
    updateMetaTag 
} from 'utils/index';
import TopSongs from 'containers/TopSongs';
import PlaylistToggle from 'components/PlaylistToggle';
import { 
    SUCCESS,
    REFRESH_PLAYLIST_TIME,
    MY_SONGS_TITLE,
    DEFAULT_PLAYLIST_TITLE,
} from 'constants/index';
import { 
    DEFAULT_PLAYLIST_ID,
    TOP_SONGS_PLAYLIST_ID,
    USER_SONGS_PLAYLIST_ID,
} from 'constants/api';

import './Home.scss';

import {
    IPendingTrack, 
    IPlanObject,
    IPlaylists,
    IChangeMusic,
    ITrack,
    ICurrentMusic,
    IUserState,
    IMusicState,
} from 'interfaces';

// @ts-ignore
const SongListTrackedScroll = trackWindowScroll(SongList);

interface IHomeProps {
    music: IMusicState;
    // eslint-disable-next-line no-unused-vars
    getPlaylist: (arg: any) => any;
    // eslint-disable-next-line no-unused-vars
    changeMusic: (arg: IChangeMusic) => any;
    // eslint-disable-next-line no-unused-vars
    setPlayerOpen: (arg: boolean) => any;
    createMusicQueue?: IPendingTrack[];
    // eslint-disable-next-line no-unused-vars
    setUserPlaylist: (arg: IPlanObject) => any;
    isAuthenticated?: boolean;
    user?: IUserState;
    [key: string]: any;
}

const ShareBar = deviceInfo.isDesktop ? React.lazy(() => import('containers/ShareBar')) : React.Fragment;

function Home(props: IHomeProps) {
    const {
        music = {} as IMusicState,
        getPlaylist = () => {},
        changeMusic = () => {},
        setPlayerOpen = () => {},
        setUserPlaylist = () => {},
        isAuthenticated = false,
        user = {} as IUserState,
        createMusicQueue,
    } = props;

    const {
        currentMusic = {} as ICurrentMusic,
        playlists = {} as IPlaylists,
    } = music;

    const location = useLocation();

    // get url params
    const { 
        playlistId, 
        musicId 
    } = useParams();

    const [isAllPlaylistLoaded, setIsAllPlaylistLoaded] = useState(false);
    const [isDefaultMusicSet, setDefaultMusicSet] = useState(false);
    const [currentPlaylist, setCurrentPlaylist] = useState<IPlanObject>();

    // onMount - get all playlist data
    useEffect(() => {
        const asyncFn = async () => {
            // playslists IDs for default view.
            const allPlaylists = [
                TOP_SONGS_PLAYLIST_ID,
                DEFAULT_PLAYLIST_ID,
            ];
            // 1. loop allPlaylists to create array of promises
            // 2. parallel call using Promise.allSettled
            const playlistPromises = allPlaylists.map((id = '') => getPlaylist({ playlistId: id }));
            // eslint-disable-next-line no-unused-vars
            const playlistResults = await Promise.allSettled(playlistPromises);
            
            // create user's playlist by filtering DEFAULT_PLAYLIST_ID with userID
            if (playlistResults[1].status === 'fulfilled') {
                setUserPlaylist({
                    defaultPlaylistData: playlistResults[1].value,
                    user
                });

                setCurrentPlaylist({
                    data: playlistResults[1].value,
                    playlistTitle: DEFAULT_PLAYLIST_TITLE
                });
            }

            setIsAllPlaylistLoaded(true);
        };

        asyncFn();
    }, [setIsAllPlaylistLoaded]);

    // get playlist and song
    useEffect(() => {
        const changeDefaultMusic = () => {
            // 3. if there is 'musicId' from URL ('/playlistId/musicId')
            // change music by find match musicId in loaded playlist
            let currentPlaylistId = playlistId || '';
            let currentMusicId = musicId;
            let currentMusicIndex = 0;

            if (currentMusicId && playlists[currentPlaylistId]) {
                if (Array.isArray(playlists[currentPlaylistId].musics)) {
                    const musicIndex = playlists[currentPlaylistId].musics.findIndex((m: ITrack) => m.musicId === currentMusicId);
                    currentMusicIndex = musicIndex >= 0
                        ? musicIndex
                        : 0;
                }
            } else {
                currentPlaylistId = TOP_SONGS_PLAYLIST_ID || '';
                if (playlists[currentPlaylistId] && Array.isArray(playlists[currentPlaylistId].musics)) {
                    currentMusicId = playlists[currentPlaylistId].musics[0]
                        ? playlists[currentPlaylistId].musics[0].musicId
                        : '';
                }
            }

            if (playlists[currentPlaylistId] && playlists[currentPlaylistId].status === SUCCESS) {
                const {
                    musicName,
                    cover,
                } = playlists[currentPlaylistId].musics[currentMusicIndex];

                updateMetaTag({
                    tagName: 'og:title',
                    content: musicName,
                });

                updateMetaTag({
                    tagName: 'og:image',
                    content: cover,
                });

                changeMusic({
                    playlistId: currentPlaylistId,
                    musicId: currentMusicId,
                    index: currentMusicIndex
                });

                setDefaultMusicSet(true);
            }
        };

        if (isAllPlaylistLoaded) {
            // set default music or land from shared url. Only run once
            if (!isDefaultMusicSet && !currentMusic.musicId) {
                changeDefaultMusic();
                // open music detail
                if (playlistId && musicId && !currentMusic.isPlayerOpen) {
                    setPlayerOpen(true);
                }
            }

            // on music route change
            if (currentMusic.musicId && currentMusic.musicId !== musicId) {
                if (playlistId ) {
                    changeMusic({
                        playlistId,
                        musicId,
                    });
                }
            }
        }
        
    }, [playlists, playlistId, musicId, isAllPlaylistLoaded, isDefaultMusicSet, setDefaultMusicSet]);

    useEffect(() => {
        // close modal on home url
        if ((location.pathname === '/') && currentMusic.isPlayerOpen) {
            setPlayerOpen(false);
        }
    },[location]);

    // Refresh default playlist when there is item in createMusicQueue
    // the reducer will remove the queue item if new song added into the playlist
    // NOTE: the Router will unmount the component between routes
    useEffect(()=> {
        let refreshTimer: any;

        if (Array.isArray(createMusicQueue)) {
            if (createMusicQueue.length) {
                clearTimeout(refreshTimer);
                refreshTimer = setTimeout(()=> {
                    getPlaylist({ playlistId: DEFAULT_PLAYLIST_ID });
                }, REFRESH_PLAYLIST_TIME);
            } else {
                clearTimeout(refreshTimer);
            }
        }

        return  function cleanUp() {
            if (Array.isArray(createMusicQueue) && !createMusicQueue.length) {
                clearTimeout(refreshTimer);
            }
        };
    }, [createMusicQueue]);

    const togglePlaylist = (playlistTitle = '') => {
        if (playlistTitle === MY_SONGS_TITLE) {
            setCurrentPlaylist({
                data: playlists[USER_SONGS_PLAYLIST_ID],
                playlistTitle: MY_SONGS_TITLE
            });
        } else {
            setCurrentPlaylist({
                data: playlists[DEFAULT_PLAYLIST_ID || ''],
                playlistTitle: DEFAULT_PLAYLIST_TITLE
            });
        }
    };

    return (
        <Container 
            className="home"
            maxWidth="lg"
        >
            <div className="home__intro">
                <h2>Make Your Own Songx</h2>
                <div className="home__intro-butons-section">
                    <CreateButton />
                    {!deviceInfo.isDesktop && <ShareButton asTextButton />}
                </div>
                <Suspense>
                    <ShareBar />
                </Suspense>
            </div>
            <MiniPlayer 
                // @ts-ignore
                isModalOpen={currentMusic.isPlayerOpen}
                setIsModalOpen={setPlayerOpen}
            />
            <TopSongs />
            {isAllPlaylistLoaded && currentPlaylist && (
                <>
                    <PlaylistToggle
                        className="my-1"
                        selectedValue={currentPlaylist.playlistTitle || DEFAULT_PLAYLIST_TITLE}
                        isAuthenticated={isAuthenticated}
                        onChange={togglePlaylist}
                    />
                    <SongListTrackedScroll 
                        // @ts-ignore
                        playlistData={currentPlaylist.data}
                    />
                </>
            )}
        </Container>
    );
}

export default Home;
