import { 
    useState,
    useEffect,
    useRef,
} from 'react';

function useSticky(buffer = 0) {
    const [isSticky, setIsSticky] = useState(false);

    const targetRef = useRef<HTMLDivElement>(null);

    useEffect(()=> {
        const handleScroll = () => {
            if (!targetRef || !targetRef.current) {
                return;
            }

            const stickyPos = targetRef.current.offsetTop + buffer;

            if (window.pageYOffset > stickyPos) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return function clearUp() {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return [
        targetRef,
        isSticky
    ] as const;
}

export default useSticky;
