/* eslint-disable no-unused-vars */
import { 
    lazy,
    Suspense,
    useEffect,
    useState, 
} from 'react';
import { 
    Routes, 
    Route, 
    Navigate,
    useLocation,
} from 'react-router-dom';
import ProtectedRoute from 'containers/ProtectedRoute';
import Drawer from '@mui/material/Drawer';
import Spinner from 'components/Spinner';
import MainHeader from 'components/MainHeader';
import Footer from 'components/Footer';
import Home from 'containers/Home';
import DrawerMenu from 'containers/DrawerMenu';
import ScrollToTop from 'components/ScrollToTop';
import {
    PLAYLIST_ID_MUSIC_ID,
    PLAYLIST_BY_ID,
    CREATE,
    HOME,
    SIGN_IN,
    MY_SONGX,
    PROFILE,
    REWARDS,
    PRIVACY_POLICY,
    TERMS_CONDITIONS,
} from 'constants/routes';
import { firebaseAuth } from 'utils/firebase';

import './App.scss';
import {
    IAccountState, IPlanObject, 
    IUserState
} from 'interfaces';

const Music = lazy(() => import('../../containers/Music'));
const SignInScreen = lazy(()=> import('../../containers/SignIn'));
const MySongx = lazy(()=> import('../../containers/MySongx'));
const Profile = lazy(()=> import('../../containers/Profile'));
const Rewards = lazy(()=> import('../../pages/Rewards'));
const PrivacyPolicy = lazy(()=> import('../../pages/PrivacyPolicy'));
const TermsConditions = lazy(()=> import('../../pages/TermsConditions'));

const BG_CSS = { HOME: 'bg-home' };

interface IAppProps {
    user?: IUserState;
    // eslint-disable-next-line no-unused-vars
    getSongxUserProfile?: (data: IAccountState | IPlanObject)=> any;
    signOutUser?: ()=> Promise<any>;
}

function App(props: IAppProps) {
    const {
        user,
        getSongxUserProfile = ()=>{},
        signOutUser = ()=>{},
    } = props;

    const [bgClassName, setBgClassName] = useState(BG_CSS.HOME);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isAuthChecked, setIsAuthChecked] = useState(false);

    const location = useLocation();

    const {
        pathname 
    } = location;

    // on App mount or refresh
    useEffect(() => {
        const asyncFn = async ()=> {
            try {
                const {
                    currentUser
                } = firebaseAuth;

                // if user authenticated and reload the page
                if (currentUser && !user) {
                    await getSongxUserProfile(currentUser);
                    setIsAuthChecked(true);
                } else {
                    setIsAuthChecked(true);
                }
            } catch(err) {
                // sign out firebase if something went wrong
                await signOutUser();
                setIsAuthChecked(true);
            }
        };

        asyncFn();
    },[]);

    useEffect(() => {
        if (pathname === HOME) {
            setBgClassName(BG_CSS.HOME);
        } else {
            setBgClassName('');
        }
    }, [pathname]);


    const toggleDrawer = (newOpen: boolean) => {
        setIsDrawerOpen(newOpen);
    };

    const classNames = `app ${bgClassName}`.trim();

    if (!isAuthChecked) {
        return (
            <div className={classNames}>
                <Spinner className='spinner--cover' />
            </div>
        );
    }

    return (
        <>
            <div className={classNames}>
                <ScrollToTop />
                <MainHeader 
                    isDrawerOpen={isDrawerOpen}
                    toggleDrawer={toggleDrawer}
                />
                <Suspense fallback={<Spinner className='spinner--cover' />}>
                    <Routes>
                        <Route
                            path={HOME}
                            element={<Home />}
                        />
                        <Route
                            path={SIGN_IN}
                            element={<SignInScreen />}
                        />
                        <Route
                            path={PRIVACY_POLICY}
                            element={<PrivacyPolicy />}
                        />
                        <Route
                            path={TERMS_CONDITIONS}
                            element={<TermsConditions />}
                        />
                        <Route element={<ProtectedRoute />}>
                            <Route
                                path={`${CREATE}/:step`}
                                element={<Music />}
                            />
                            <Route
                                path={PROFILE}
                                element={<Profile />}
                            />
                            {/* 
                            <Route
                                path={MY_SONGX}
                                element={<MySongx />}
                            />
                            <Route
                                path={REWARDS}
                                element={<Rewards />}
                            />
                            */}
                        </Route>
                        <Route
                            path={PLAYLIST_BY_ID}
                            element={<Home />}
                        />
                        <Route
                            path={PLAYLIST_ID_MUSIC_ID}
                            element={<Home />}
                        />
                        <Route
                            path="*"
                            element={
                                <Navigate 
                                    to={HOME}
                                    replace
                                />
                            }
                        />
                    </Routes>
                </Suspense>
                <Drawer 
                    open={isDrawerOpen} 
                    onClose={()=>{ toggleDrawer(false); }}
                >
                    <DrawerMenu toggleDrawer={toggleDrawer} />
                </Drawer>
            </div>
            <Footer />
        </>
    );
}

export default App;

