import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ShareButton from 'containers/ShareButton';
import LikeButton from 'containers/LikeButton';
import { abbreviateNumber } from 'utils/formatters';

import { IPlanObject } from 'interfaces';

import './SideBar.scss';

interface ISideBarProps {
    className?: string;
    currentTrack: IPlanObject;
    [key: string]: any;
}


function SideBar(props: ISideBarProps) {
    const {
        className = '',
        currentTrack = {},
    } = props;

    const {
        views = 0,
    } = currentTrack;


    const classNames = `side-bar ${className}`.trim();

    return (
        <ul className={classNames}>
            <li>
                <ShareButton />
            </li>
            <li>
                <LikeButton />
            </li>
            <li>
                <MusicNoteIcon />
                <small>
                    {abbreviateNumber(views)}
                </small>
            </li>
        </ul>
    );
}

export default SideBar;
