import { connect } from 'react-redux';
import { 
    updateView,
    updatePlayerStatus,
    setRepeatPlaylist,
    setRepeatSingle,
    setShufflePlaylist,
} from 'actions/music';
import MiniPlayer from 'components/MiniPlayer';

import { IRootState } from 'interfaces/index';

const mapStateToProps = (state: IRootState) => (state.music);

const mapDispatchToProps = (dispatch: any) => ({ 
    updateView: (data: any) => dispatch(updateView(data)),
    updatePlayerStatus: (isPlaying: boolean) => dispatch(updatePlayerStatus(isPlaying)),
    setRepeatPlaylist: (isRepeat: boolean) => dispatch(setRepeatPlaylist(isRepeat)),
    setRepeatSingle: (isRepeatSingle: boolean) => dispatch(setRepeatSingle(isRepeatSingle)),
    setShufflePlaylist: (isShuffle: boolean) => dispatch(setShufflePlaylist(isShuffle)),
});

export default  connect(mapStateToProps, mapDispatchToProps)(MiniPlayer);
